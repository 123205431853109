import axios from 'axios'

const root = '/api/integracao/'

const Endpoints = Object.freeze({
test: 'test/time/',
contatos: 'contatos/',
contatosUpdate: 'contatos/update/',
login:"auth-token/login/",
logout:"auth-token/logoutall/",
whoami:"whoami/"
})

const Methods = Object.freeze({
  PUT: 'PUT',
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
  PATCH: 'PATCH'
})

class ApiRequestParameters {
  constructor (
    endpoint = Endpoints.Api,
    data = null,
    method = Methods.GET,
    urlParams = {},
    headers = {}
  ) {
    this.endpoint = endpoint
    this.data = data
    this.method = method
    this.urlParams = urlParams
    this.headers = headers
  }
}

async function getApiResponse (parameters) {
  const response = await axios({
    data: parameters.data,
    headers: parameters.headers,
    method: parameters.method,
    url: root + parameters.endpoint,
    withCredentials: true,
    params: parameters.urlParams
  })
  return response
}


export async function test () {
  const parameters = new ApiRequestParameters(
    Endpoints.test,
    null,
    Methods.GET
  )
  return getApiResponse(parameters)
}

export async function updateContatos (cpfs,leadId) {
  const data = {
    lead_id: leadId,
    cpfs: cpfs
  }
  const token = localStorage.getItem('wsul_token')
  const headers = {
    'Authorization': `Token ${token}`
  }
  const parameters = new ApiRequestParameters(
    Endpoints.contatos,
    data,
    Methods.POST,
    {},
    headers
  )
  return getApiResponse(parameters)
}

export async function login (username, password) {
  const data = {
    username: username,
    password: password
  }
  const parameters = new ApiRequestParameters(
    Endpoints.login,
    data,
    Methods.POST
  )
  return getApiResponse(parameters)
}

export async function logout () {
  const token = localStorage.getItem('wsul_token')
  const headers = {
    'Authorization': `Token ${token}`
  }

  const parameters = new ApiRequestParameters(
    Endpoints.logout,
    null,
    Methods.POST,
    {},
    headers
  )
  return getApiResponse(parameters)
}

export async function whoAmI () {
  const token = localStorage.getItem('wsul_token')
  const headers = {
    'Authorization': `Token ${token}`
  }
  const parameters = new ApiRequestParameters(
    Endpoints.whoami,
    null,
    Methods.GET,
    {},
    headers
  )
  return getApiResponse(parameters)
}

export async function updateContactsOnDeal (cpfs, dealId) {
  const token = localStorage.getItem('wsul_token')
  const headers ={
    'Authorization': `Token ${token}`
  }
  const payload = {
    deal_id: dealId,
    cpfs: cpfs
  }

  const parameters = new ApiRequestParameters(
    Endpoints.contatosUpdate,
    payload,
    Methods.POST,
    {},
    headers
  )
  return getApiResponse(parameters)
}



