import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { User } from '../../../domain/User'
import CircularProgress from '@mui/material/CircularProgress'

const BotaoFeedBack = ({ onClickFunction, status }) => {
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    async function fetch () {
      const user = new User()
      const me = await user.getIdentity()
      setIsLogged(me.sucess)
    }
    fetch()
  }, [])

  return isLogged == false ? (
    <Button
      disabled
      variant='outlined'
      sx={{
        width: '100%'
      }}
    >
      Você não está logado
    </Button>
  ) : status == 'LOADING' ? (
    <Button
      disabled
      variant='contained'
      sx={{
        width: '100%'
      }}
    >
      <CircularProgress size ="20px"/>
    </Button>
  ) : (
    <Button
      onClick={onClickFunction}
      variant='contained'
      sx={{
        width: '100%'
      }}
    >
      Sincronizar Contatos
    </Button>
  )
}

export default BotaoFeedBack
